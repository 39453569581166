// src/components/ProgressScreen.js
import React from 'react';
import './ProgressScreen.css';

const ProgressScreen = ({ progress }) => {
  return (
    <div className="progress-screen">
      <h2>Loading resources...</h2>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <p>{progress}% complete</p>
    </div>
  );
};

export default ProgressScreen;
