// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomeScreen from './components/HomeScreen';
import LanguageSelector from './components/LanguageSelector';
import AudioGuide from './components/AudioGuide';
import PageTransition from './components/PageTransition';
import ProgressScreen from './components/ProgressScreen';
import './App.css';

const MINIMUM_LOADING_TIME = 2000; // 2 seconds minimum loading time

// AnimatedRoutes component
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <PageTransition location={location}>
      <Routes location={location}>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/language-selector" element={<LanguageSelector />} />
        <Route path="/audio-guide/:language" element={<AudioGuide />} />
      </Routes>
    </PageTransition>
  );
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    setStartTime(Date.now());

    const handleMessage = (event) => {
      if (event.data && event.data.type === 'CACHING_PROGRESS') {
        console.log('Received progress update:', event.data.progress);
        setProgress(event.data.progress);
        if (event.data.progress === 100) {
          const elapsedTime = Date.now() - startTime;
          const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);
          setTimeout(() => setIsLoading(false), remainingTime);
        }
      }
    };

    navigator.serviceWorker.addEventListener('message', handleMessage);

    const checkServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.ready;
        console.log('Service Worker is ready');
        registration.active.postMessage({ type: 'GET_CACHING_PROGRESS' });
      } catch (error) {
        console.error('Error with Service Worker:', error);
      }
    };

    checkServiceWorker();

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
  }, [startTime]);

  if (isLoading) {
    return <ProgressScreen progress={progress} />;
  }

  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
}

export default App;
